import React, { Fragment, useState, useRef } from 'react';
import { deviceTypesEnum } from '../../common/enums';
import { socialIcons } from '../../constants/icons';
import Coordinates from './Coordinates';
import Contact from '../../models/contact';

import closeBtn from '../../assets/images/close-btn.svg';

type Props = {
  deviceType: string,
  contact: Contact
}

const InfoBar: React.FC<Props> = ({ deviceType, contact }) => {
  const [isCloseBtnVisible, setIsCloseBtnVisible] = useState(false);
  const [isWebInfobarVisible, setIsWebInfobarVisible] = useState(false);
  const webInfobarRef = useRef<HTMLDivElement>(null);
  const isDeviceTypeWeb = deviceType === deviceTypesEnum.WEB;

  const toggleInfoBar = (newValue: boolean) => (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    e.stopPropagation();
    setIsCloseBtnVisible(newValue);
    setIsWebInfobarVisible(newValue);
  };

  const getWebInfobarState = () => {
    // if element already has 'open' class,
    // replace with 'closed', else no class
    return isWebInfobarVisible
      ? 'open'
      : webInfobarRef.current?.classList.contains('open') ? 'closed' : '';
  }

  return (
    <Fragment>
      <div id="infoBar"
        onClick={toggleInfoBar(true)}
        onMouseLeave={() => setIsWebInfobarVisible(false)}
        className={`infobar-container ${deviceType} ${isCloseBtnVisible ? 'opened' : 'closed'}`}>
        <div className="header-container">
          <h5>INFO_</h5>
          {(isCloseBtnVisible && !isDeviceTypeWeb)
            && <button className="close-btn" onClick={toggleInfoBar(false)} aria-label="Close">
              <img src={closeBtn} alt="close info menu" />
            </button>
          }
        </div>
        {!isDeviceTypeWeb &&
          <div className="infobar-content">
            <div className="infobar-contact">
              <div className="contact-container">
                <div className="contact-item name">JULES MORETTI</div>
                <div className="contact-item">
                  <Coordinates coordinates={contact.coordinates} />
                </div>
              </div>
            </div>
            <hr className="separator" />
            <div className="infobar-social">
              <a href={`https://www.linkedin.com/in/${contact.linkedin}`} rel="noopener noreferrer" target="_blank">
                <img src={socialIcons.linkedin} alt="LinkedIn" />
              </a>
              <a href={`https://www.behance.net/${contact.behance}`} rel="noopener noreferrer" target="_blank">
                <img src={socialIcons.behance} alt="Behance" />
              </a>
              <a href={`https://github.com/${contact.github}`} rel="noopener noreferrer" target="_blank">
                <img src={socialIcons.github} alt="Github" />
              </a>
              <a href={`https://www.dribbble.com/${contact.dribbble}`} rel="noopener noreferrer" target="_blank" className="social-link">
                <img src={socialIcons.dribbble} alt="Dribbble" />
              </a>
            </div>
            <a href={`mailto:${contact.email}`} target="_self" className="contact-btn">GET IN TOUCH</a>
          </div>}

        {isDeviceTypeWeb &&
          <div
            ref={webInfobarRef}
            className={`web-wrapper ${getWebInfobarState()}`}
            onClick={() => setIsWebInfobarVisible(true)}
          >
            <div className="web-infobar-content"
              onMouseLeave={() => setIsWebInfobarVisible(false)}>
              <div className="infobar-contact">
                <div className="contact-container">
                  <div className="contact-item name">JULES MORETTI</div>
                  <div className="contact-item">
                    <Coordinates coordinates={contact.coordinates} />
                  </div>
                </div>
              </div>
              <div className="infobar-social">
                <a href={`https://www.linkedin.com/in/${contact.linkedin}`} rel="noopener noreferrer" target="_blank">
                  <img src={socialIcons.linkedin} alt="LinkedIn" />
                </a>
                <a href={`https://www.behance.net/${contact.behance}`} rel="noopener noreferrer" target="_blank">
                  <img src={socialIcons.behance} alt="Behance" />
                </a>
                <a href={`https://github.com/${contact.github}`} rel="noopener noreferrer" target="_blank">
                  <img src={socialIcons.github} alt="Github" />
                </a>
                <a href={`https://www.dribbble.com/${contact.dribbble}`} rel="noopener noreferrer" target="_blank">
                  <img src={socialIcons.dribbble} alt="Dribbble" />
                </a>
              </div>
              <a href={`mailto:${contact.email}`} target="_self" className="contact-btn">SEND AN EMAIL</a>
            </div>
          </div>}
      </div>
    </Fragment >
  )
}

export default InfoBar;
