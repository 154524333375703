export const cellNumber = '2134007436';

export const scrollOptions = {
  // duration of the scroll per 1000px, default 500
  speed: 1000,

  // minimum duration of the scroll
  minDuration: 250,

  // maximum duration of the scroll
  maxDuration: 3000,

  // should animated scroll be canceled on user scroll/keypress
  // if set to "false" user input will be disabled until animated scroll is complete
  cancelOnUserAction: true,
};
