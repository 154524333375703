import React from 'react';

type DesignProps = {
  design: any
}

const DesignSection: React.FC<DesignProps> = ({ design }) => {
  return (
    <div className="design">
      Designed at <span className="design-highlight"><span className="highlight"></span><a className="text" href={design.url} target="blank">{design.name}</a></span>
    </div>
  )
}

export default DesignSection;
