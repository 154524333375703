import React from 'react';
import Moment from 'react-moment';
import DesignSection from './DesignSection';
import TagsSection from './TagsSection';
import { bulletPointIcons } from '../../constants/icons';
import Project from '../../models/project';

type MiddleSectionProps = {
  project: Project,
  isMobileDevice: boolean
}


const MiddleSection: React.FC<MiddleSectionProps> = ({ project, isMobileDevice }) => {
  const date = new Date(project['_header']['3_date'].seconds * 1000);

  return (
    <div className="project-middle-section">
      <div className="date"><Moment format="MMM YYYY">{date}</Moment></div>
      <div className="project-list">
        <div className="project-item">
          <img className="bullet-point-icon" src={bulletPointIcons.targetUser} alt="Target user" />
          <span>{project['_header']['4_targetUser']}</span>
        </div>
        <div className="project-item">
          <img className="bullet-point-icon" src={bulletPointIcons.product} alt="Product" />
          <span>{project['_header']['5_product']}</span>
        </div>
        <div className="project-item">
          <img className="bullet-point-icon" src={bulletPointIcons.tools} alt="Tools" />
          <span>{project['_header']['6_tools']}</span>
        </div>
        <div className="project-item">
          <img className="bullet-point-icon" src={bulletPointIcons.duration} alt="Duration" />
          <span>{project['_header']['7_duration']}</span>
        </div>
      </div>
      {isMobileDevice && <DesignSection design={project['_header']['8_designedAt']} />}
      {isMobileDevice && <TagsSection tags={project['_header']['9_tags']} />}
    </div>
  )
}

export default MiddleSection;
