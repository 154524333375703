import caring from '../assets/images/Personalization-Icons/Caring.svg';
import collabrative from '../assets/images/Personalization-Icons/Collaborative.svg';
import creativity from '../assets/images/Personalization-Icons/Creativity.svg';
import curiosity from '../assets/images/Personalization-Icons/Curiosity.svg';
import innovation from '../assets/images/Personalization-Icons/Innovation.svg';
import leadership from '../assets/images/Personalization-Icons/Leadership.svg';
import precision from '../assets/images/Personalization-Icons/Precision.svg';
import problemSolving from '../assets/images/Personalization-Icons/Problem-Solving.svg';

import targetUser from '../assets/images/Bullet-Point-Icons/Target-User.svg';
import product from '../assets/images/Bullet-Point-Icons/Product-Barcode.svg';
import tools from '../assets/images/Bullet-Point-Icons/Tools.svg';
import duration from '../assets/images/Bullet-Point-Icons/Duration.svg';
import recognition from '../assets/images/Bullet-Point-Icons/Recognition.svg';

import dribbble from '../assets/images/Social-Media-Icons/Dribbble.svg';
import behance from '../assets/images/Social-Media-Icons/Behance.svg';
import linkedin from '../assets/images/Social-Media-Icons/Linkedin.svg';
import github from '../assets/images/Social-Media-Icons/Github.svg';

export const personalizationIcons = [
  { image: problemSolving, name: 'Problem Solving' },
  { image: precision, name: 'Precision' },
  { image: creativity, name: 'Creativity' },
  { image: innovation, name: 'Innovation' },
  { image: curiosity, name: 'Curiosity' },
  { image: collabrative, name: 'Collaborative' },
  { image: leadership, name: 'Leadership' },
  { image: caring, name: 'Caring' }];

export const bulletPointIcons = {
  targetUser,
  product,
  tools,
  duration,
  recognition
}

export const socialIcons = {
  dribbble,
  behance,
  linkedin,
  github
}

