import React, { useState, useEffect, useMemo, useRef } from 'react';
import { isMobileOnly, isTablet, isIOS } from 'react-device-detect';
import { deviceTypesEnum } from '../../common/enums';
import { getFullImageName, getCarouselImageNames } from '../../common/utils';
import animatedScrollTo from 'animated-scroll-to';
import { Parallax, Element } from 'rc-scroll-anim';

// Models
import Project from '../../models/project';
import BannerInfo from '../../models/bannerInfo';
import Contact from '../../models/contact';

// Components
import HeroBanner from '../HeroBanner';
import ProjectComponent from '../Project';

import { withFirebase } from '../Firebase';
import InfoBar from '../InfoBar';
import { scrollOptions } from '../../constants';
import CarouselImage from '../../models/carouselImage';
import Particles from "react-particles-js";
import particlesConfig from "../../particlesConfig";

const expanded = {
  expandedProject: '',
  setExpandedProject: (value: string) => { }
};

export const Context = React.createContext(expanded);

type Props = {
  firebase?: any;
}

const App: React.FC<Props> = ({ firebase }) => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);
  const [bannerInfo, setBannerInfo] = useState({} as BannerInfo);
  const [goToTop, setGoToTop] = useState('');
  const [contact, setContact] = useState({} as Contact);
  const [expandedProject, setExpandedProject] = useState('');
  const deviceType = isMobileOnly ? deviceTypesEnum.MOBILE
    : isTablet ? deviceTypesEnum.TABLET : deviceTypesEnum.WEB;
  const projRefs = useRef(new Map()).current;
  const isMobileDevice = deviceType === deviceTypesEnum.MOBILE;

  useEffect(() => {
    const key = window.location.hash.toLowerCase().replace(/#/, '');
    // console.log("useEffect APP A", key);
    setExpandedProject(key);
    window.setTimeout(() => {
      const elem = projRefs.get(key);
      if (elem) {
        // animatedScrollTo(elem.offsetTop, scrollOptions);
        animatedScrollTo(elem, scrollOptions);
      }
    }, 500)
  }, [projects, projRefs]);

  const getProjectsImages = (projects: Project[]) => {

    const newProjects = projects.map((proj) => {
      let storageRef = firebase.storage.ref();
      if (proj.settings && proj.settings.imgDir) {
        return storageRef.child(getFullImageName(proj.settings.imgDir, deviceType, isIOS)).getDownloadURL()
          .then((imgUrl: string) => ({ ...proj, image: imgUrl }))
      } else {
        return storageRef.child(getFullImageName(proj.imageName, deviceType, isIOS)).getDownloadURL()
          .then((imgUrl: string) => ({ ...proj, image: imgUrl }))
      }
    });
    Promise.all(newProjects)
      .then((projList) => {
        setProjects(projList as Project[]);
        setFilteredProjects(projList as Project[]);
      })
  };

  // index scroll listener
  // useEffect(() => {
  //   console.log(window.pageYOffset);
  //   window.addEventListener('scroll', () => console.log('scrolling'))
  // });


  useEffect(() => {
    firebase.bannerInfo().get()
      .then((snapshot: firebase.firestore.QuerySnapshot) => {
        setBannerInfo({ ...snapshot.docs[0].data() as BannerInfo });
      });

    firebase.contact().get()
      .then((snapshot: firebase.firestore.QuerySnapshot) => {
        setContact({ ...snapshot.docs[0].data() as Contact });
      });

    firebase.projects().get()
      .then((snapshot: firebase.firestore.QuerySnapshot) => {
        const projects: Project[] = snapshot.docs.reduce((acc: Project[], doc: firebase.firestore.DocumentSnapshot) => {
          return [...acc, { id: doc.id, ...doc.data() } as Project]
        }, []);
        getProjectsImages(projects);
      });
  }, [firebase]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCarouselImages = (project: Project) => {
    let storageRef = firebase.storage.ref();
    let images = getCarouselImageNames(project.settings.imgDir, project.settings.carouselImg, deviceType);
    const imagesPromise = images.map((image: CarouselImage) => {
      return storageRef.child(image.imgUrl).getDownloadURL()
        .then((imgUrl: string) => ({ name: image.name, imgUrl, description: image.description }))
    });
    return Promise.all(imagesPromise);
  };

  const memoizedProjects = useMemo(() => filteredProjects.sort((a: Project, b: Project) => a.date - b.date).reverse().map((proj: Project, index: number) => {
    if (proj._header) {
      return <ProjectComponent
        projRef={projRefs}
        key={proj.id}
        index={index}
        project={proj}
        deviceType={deviceType}
        carouselImagesProp={(project: Project) => getCarouselImages(project)} />
    }
    return true;
  }), [filteredProjects, deviceType, projRefs]); // eslint-disable-line react-hooks/exhaustive-deps

  const scrollTo = (e: React.MouseEvent<HTMLButtonElement> | null, location?: number) => {
    if (e) e.preventDefault();
    animatedScrollTo(location || 0, scrollOptions);
  };

  return (
    <Context.Provider value={{ expandedProject, setExpandedProject }}>
      <div className={`app-container ${deviceType}`}>
        <div className="particles-container">
          <Particles canvasClassName={`particles-canvas ${deviceType}`} params={particlesConfig} />
          <HeroBanner bannerInfo={bannerInfo} deviceType={deviceType} />
        </div>

        <Element
          id="Scroll-Pack"
          style={{
            position: 'relative',
          }}
         >
          <div className="projects-list">{memoizedProjects}</div>
          <Parallax
            className="pack-page"
            location="Scroll-Pack"
            animation={{
              playScale: [1, 2],
              onStart: () => { setGoToTop('start'); },
              onCompleteBack: () => { setGoToTop('end'); },
              //onComplete: () => { setGoToTop('onComplete'); },
              //onStartBack: () => { setGoToTop('onStartBack'); },
            }}
          >
              {!isMobileDevice && <button onClick={scrollTo} className={"go-to-top " + goToTop}>GO TO TOP<span className="lines">___</span></button>}
          </Parallax>
        </Element>
        <InfoBar deviceType={deviceType} contact={contact} />
      </div>
    </Context.Provider>
  )
};

export default withFirebase(App);
