import { deviceTypesEnum } from "./enums";
import { isIOS } from 'react-device-detect';

const computeImageVariant = (deviceType: string, isIOS: boolean): string => {
  const defaultVariant = '';
  const variantWidthMapper = {
    [deviceTypesEnum.MOBILE]: '',
    [deviceTypesEnum.TABLET]: '@2x',
    [deviceTypesEnum.WEB]: '@3x',
  };

  // old implementation logic
  const deviceWidthVariant = variantWidthMapper[deviceType];

  // At least 2x for Apple devices
  // Source: https://developer.apple.com/design/human-interface-guidelines/ios/icons-and-images/image-size-and-resolution/
  const deviceTypeVariant = isIOS ? '@2x' : defaultVariant;

  // choose the higher res option
  return deviceWidthVariant > deviceTypeVariant ? deviceWidthVariant : deviceTypeVariant;
};

export const getFullImageName = (imageName: string, deviceType: string, isIOS: boolean): string => {
  const variant = computeImageVariant(deviceType, isIOS);
  return `images/${imageName}-${deviceType}${variant}.jpg`;
};

export const getCarouselImageNames = (projectName: string, imageNames: { name: string, desc: string }[], deviceType: string) => {
  const variant = computeImageVariant(deviceType, isIOS);
  return imageNames.map(image => ({
    imgUrl: `${projectName}/${image.name}-${deviceType}${variant}.jpg`,
    description: image.desc,
    name: image.name
  }));
};
