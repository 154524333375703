import {IParticlesParams} from "react-particles-js";

const particlesParams: IParticlesParams = {
  "particles":{
    "number":{
      "value":26,
      "density":{
        "enable":true,
        "value_area":800
      }
    },
    "color":{
      "value":['#FF00FF', '#13FF00', '#FF6700', '#FFF800'],
    },
    "shape":{
      "type":"circle",
      "stroke":{
        "width":0,
        "color":"#000000"
      },
      "polygon":{
        "nb_sides":0
      },
      "image":{
        "src":"img/github.svg",
        "width":100,
        "height":100
      }
    },
    "opacity":{
      "value":1,
      "random":false,
      "anim":{
        "enable":false,
        "speed":1,
        "opacity_min":0.1,
        "sync":false
      }
    },
    "size":{
      "value":10,
      "random":true,
      "anim":{
        "enable":false,
        "speed":10,
        "size_min":0.1,
        "sync":false
      }
    },
    "line_linked":{
      "enable":false,
      "distance":150,
      "color":"#ffffff",
      "opacity":0.4,
      "width":1
    },
    "move":{
      "enable":true,
      "speed": 0.4,
      "direction":"none",
      "random":false,
      "straight":false,
      "out_mode":"out",
      "bounce":false,
      "attract":{
        "enable":false,
        "rotateX":600,
        "rotateY":1200
      }
    }
  },
  "interactivity":{
    "detect_on":"canvas",
    "events":{
      "onhover":{
        "enable":false,
        "mode":"grab"
      },
      "onclick":{
        "enable":false,
        "mode":"push"
      },
      "resize":true
    },
    "modes":{
      "grab":{
        "distance":140,
        "line_linked":{
          "opacity":1
        }
      },
      "bubble":{
        "distance":400,
        "size":40,
        "duration":2,
        "opacity":8,
      },
      "repulse":{
        "distance":200,
        "duration":0.4
      },
      "push":{
        "particles_nb":4
      },
      "remove":{
        "particles_nb":2
      }
    }
  },
  "retina_detect":true
};

export default particlesParams;