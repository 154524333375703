import React, { useRef, Fragment } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CarouselImage from '../../models/carouselImage';

const responsive = {
  desktop: {
    // breakpoint: { max: 4000, min: 1024 },
    breakpoint: { max: 4000, min: 1200 },
    items: 1,
  },
  tablet: {
    // breakpoint: { max: 1024, min: 464 },
    breakpoint: { max: 1200, min: 480 },
    items: 1,
  },
  mobile: {
    //breakpoint: { max: 464, min: 0 },
    // breakpoint: { max: 360, min: 0 },
    breakpoint: { max: 480, min: 0 },
    items: 1,
  },
};

type Props = {
  deviceType: string,
  images: CarouselImage[],
}

const CustomCarousel: React.FC<Props> = ({ deviceType, images }) => {
  const carouselRef = useRef<any>(null);

  return (
    <Fragment>
      <Carousel
        showDots
        ref={carouselRef}
        responsive={responsive}
        keyBoardControl={false}
        infinite
        minimumTouchDrag={80}
        transitionDuration={800}
        ssr={true}
        centerMode={deviceType !== 'mobile' ? true : false} >
        {images.map((image: CarouselImage, index: number) => {
          return (
            <figure key={index}>
              <img
                className="image"
                draggable={false}
                src={image.imgUrl}
                alt={image.name}
              />
              <figcaption className="image-caption">{image.description}</figcaption>
            </figure>
          )
        })}
      </Carousel>
    </Fragment>
  )
}

export default CustomCarousel;
