import React from 'react';

type Props = {
  isExpanded: boolean
  deviceType: string;
  changeState: () => void
}

const ViewProject: React.FC<Props> = ({ isExpanded, changeState, deviceType }) => {
  const ViewProjectButton =
    <div className="container">
      <span>View Project</span>
      <span>&nbsp;</span>
      <span className={isExpanded ? "rotated" : ""}>></span>
    </div>

  return (
    <div className={"view-projects " + deviceType} onClick={changeState}>
      {ViewProjectButton}
    </div>
  )
}

export default ViewProject;
