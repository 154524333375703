import * as firebase from 'firebase/app';
import 'firebase/firestore';
import "firebase/storage";

const config = {
  apiKey: 'AIzaSyA_paQenIiiDUKK53QdU2wVtQKVmEjOZeg',
  authDomain: 'jules-digital-2-0.firebaseapp.com',
  databaseURL: 'https://jules-digital-2-0.firebaseio.com/',
  projectId: 'jules-digital-2-0',
  storageBucket: 'gs://jules-digital-2-0.appspot.com/',
};

class Firebase {
  db: firebase.firestore.Firestore;
  storage: firebase.storage.Storage;
  constructor() {
    const app = firebase.initializeApp(config);

    this.db = app.firestore();
    this.storage = app.storage();
  }

  // *** User API ***
  projects = () => this.db.collection('projects');

  bannerInfo = () => this.db.collection('banner');

  contact = () => this.db.collection('contact');
}

export default Firebase;
