import React from 'react';

type Props = {
  coordinates: {
    lat: number,
    long: number
  }
}

const Coordinates: React.FC<Props> = ({ coordinates }) => {
  return (
    <a
      className="coordinates"
      href={`http://maps.google.com/?q=${coordinates?.lat},${coordinates?.long}`}
      target="_blank"
      rel="noopener noreferrer">
      {coordinates?.lat.toFixed(4)}&#176;N, {coordinates?.long.toFixed(4)}&#176;W
    </a>
  )
}

export default Coordinates;
