import React, { Fragment, useContext, useEffect, useState } from 'react';
// import { Parallax } from 'rc-scroll-anim';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Project from '../../models/project';
import { deviceTypesEnum } from '../../common/enums';
import { bulletPointIcons } from '../../constants/icons';
import TagsSection from './TagsSection';
import DesignSection from './DesignSection'
import MiddleSection from './MiddleSection';
import CustomCarousel from './Carousel';
import ViewProject from './ViewProject';
import animatedScrollTo from 'animated-scroll-to';
import { scrollOptions } from '../../constants';
import { Context } from '../App';
import CarouselImage from '../../models/carouselImage';

type Props = {
  index: number,
  project: Project;
  deviceType: string;
  carouselImagesProp: any;
  projRef: any;
}

const ProjectComponent: React.FC<Props> = ({
  index,
  project,
  deviceType,
  carouselImagesProp,
  projRef
}) => {
  const [carouselImages, setCarouselImages] = useState<CarouselImage[]>([]);
  const [isExpandingProject, setIsExpandingProject] = useState(false);
  const isMobileDevice = deviceType === deviceTypesEnum.MOBILE;
  const isWebDevice = deviceType === deviceTypesEnum.WEB;
  const context: any = useContext(Context);
  let projectKey = '';
  projectKey = project['_header']['1_title'].toLowerCase().replace(/ /g, '-');

  // ADD TIAA project
  // ADD Logitech sync to project list
  // GHub Macro's Editor

  const getCarouselImages = () => {
    // console.log('getCarouselImages', project['_header']['1_title'], project['settings']['carouselImg'].length, carouselImages.length);
    // if (!carouselImages.length) {
      // console.log('getCarouselImages passed: ', )
      carouselImagesProp(project).then((imagesUrl: any) => {
        // console.log('getCarouselImages ImagesUrl: ', project, imagesUrl);
        setCarouselImages(imagesUrl);
      });
    // }
  };

  useEffect(() => {
    if (context.expandedProject === projectKey) {
      // console.log('useEffect A');
      getCarouselImages();
    }
  });

  // index scroll listener
  useEffect(() => {
    // console.log(window.pageYOffset);
    window.addEventListener('scroll', () => {

    // const winScroll =
    //   document.body.scrollTop || document.documentElement.scrollTop;

    // const height =
      // document.documentElement.scrollHeight -
      // document.documentElement.clientHeight;

    // const scrolled = winScroll / height;
      // if (projectKey === "harmony-express") {
      //   console.log("--------------------")
      // }
      // console.log(
      //   projectKey,
      //   // Math.floor(scrolled),
      //   winScroll,
      //   projRef.get(projectKey).offsetTop,
      //   height
      //  );
    })
  });

  const expandProject = () => {
    console.log('expandProject ', projectKey);
    const newValue = context.expandedProject === projectKey ? '' : projectKey;
    setIsExpandingProject(true);
    // console.log('expandProject ', projectKey, window.location.hash, newValue, newValue.length);
    context.setExpandedProject(newValue);
    if (newValue.length) {
      window.location.hash = projectKey;
    } else {
      var noHashURL = window.location.href.replace(/#.*$/, '');
      window.history.replaceState('', document.title, noHashURL);
    }
    window.setTimeout(() => {
      getCarouselImages();
      // const location = projRef.get(projectKey)?.offsetTop;
      // const { offsetTop } = projRef.get(projectKey);
      // console.log('expandProject', offsetTop, projectKey, newValue, projRef.get(projectKey), projRef.get(projectKey).getBoundingClientRect());
      // scrollTo(null, location);
      // scrollTo(null, offsetTop);
      // scrollTo(null, 143);
      // scrollTo(projRef.get(projectKey), 143);
      animatedScrollTo(projRef.get(projectKey), scrollOptions)
      // scrollTo(null, projRef.get(projectKey).getBoundingClientRect().top);
    }, 500)
  };

  const scrollTo = (e: React.MouseEvent<HTMLButtonElement> | null, location?: number) => {
    if (e) e.preventDefault();
    animatedScrollTo(location || 0, scrollOptions);
  };

  return (
    <div
      ref={(inst: HTMLDivElement) => projRef.set(projectKey, inst)}
      className={"project-container " + deviceType}>
      <Accordion aria-expanded={true}>
        <Card>
          <Card.Header>
            <div className="header-container" style={{ backgroundImage: `linear-gradient(0, ${project.settings.detailBgColor}, ${project.settings.detailBgColor}00 50%)` }}>
              <div className="work-order-container">
                <div className={`work ${isExpandingProject ? 'transparent' : ''}`}>W{isWebDevice &&
                  <span>
                    <span className="item o">O</span>
                    <span className="item r">R</span>
                    <span className="item k">K</span>
                  </span>
                }</div>
                <hr className="work-order-separator" />
                <div className="work-order">
                  <div className="leading-index">0</div>
                  <div className="full-index">
                    <span className="new-index">{index + 1}</span>
                  </div>
                </div>
              </div>
              <div className="project-header">
                {/*(!isMobileDevice && context.expandedProject !== projectKey)
                  && <button onClick={scrollTo} className="go-to-top">GO TO TOPA<span className="lines">__</span></button>*/}

                {project.image &&<div className={project.settings.imgBgColor ? "img-container" : "img-container no-effect"} style={{ backgroundColor: project.settings.imgBgColor }}><img src={project.image} alt="Project" /></div>}
                <div className={project.settings.coming_soon ? "header-text coming-soon" : "header-text"}>
                  <div id={project["_header"]["1_title"]} className="header-title">
                    <div className="title-highlight" />
                    <h5 className="title">{project['_header']['1_title']}</h5>
                  </div>

                  <p className="description">{project['_header']['2_subtitle']}</p>


                  {
                    project.settings.coming_soon ?
                      <div className="coming-soon-container">
                        <span>coming soon</span>
                      </div>
                    : null
                  }


                  <br />
                  {!isMobileDevice && !project.settings.coming_soon && <MiddleSection project={project} isMobileDevice={isMobileDevice} />}
                  {!isMobileDevice && !project.settings.coming_soon && <DesignSection design={project['_header']['8_designedAt']} />}
                </div>

                {
                  !project.settings.coming_soon ?
                    <Accordion.Toggle as={'div'} eventKey="0" className={'expand-button'}>
                      <ViewProject
                        isExpanded={context.expandedProject === projectKey}
                        deviceType={deviceType}
                        changeState={expandProject}
                       />
                    </Accordion.Toggle>
                  : null
                }
              </div>
            </div>
            {!isMobileDevice && <TagsSection tags={project['_header']['9_tags']} />}
          </Card.Header>
          <Card.Body>
            <Accordion.Collapse eventKey="0" in={context.expandedProject === projectKey}>
              <Fragment>
                {isMobileDevice && !project.settings.coming_soon && <MiddleSection project={project} isMobileDevice={isMobileDevice} />}
                <div className="description-section" style={{ backgroundColor: project.settings.detailBgColor }}>
                  <div className="text">
                    <div className="left-side">
                      <h5>{project.detail['1_title']}</h5>
                      <br />
                      <h6 className="section-header">The challenge</h6>
                        {
                          typeof project.detail['2_challenge'] == 'string' ?
                            <p>{project.detail['2_challenge']}</p> :
                            Object.entries(project.detail['2_challenge']).map((e, index) => <p key={index}>{Object.assign([], e[1]).join('')}</p>)
                        }
                      <h6 className="section-header">The Design Goal</h6>
                        {
                          typeof project.detail['2a_goal'] == 'string' ?
                            <p>{project.detail['2a_goal']}</p> :
                            Object.entries(project.detail['2a_goal']).map((e, index) => <p key={index}>{Object.assign([], e[1]).join('')}</p>)
                        }
                      <h6 className="section-header">The Design Process</h6>
                        {
                          typeof project.detail['3_process'] == 'string' ?
                            <p>{project.detail['3_process']}</p> :
                            Object.entries(project.detail['3_process']).map((e, index) => <p key={index} className={index + "<index"}>{Object.assign([], e[1]).join('')}</p>)
                        }
                      <h6 className="section-header">The Solution</h6>
                        {
                          typeof project.detail['4_solution'] == 'string' ?
                            <p>{project.detail['4_solution']}</p> :
                            Object.entries(project.detail['4_solution']).map((e, index) => <p key={index}>{
                              Object.assign([], e[1]).join('').split(' n#@ ').map((text, index) => (
                                <React.Fragment key={`${text}-${index}`}>
                                  {text}
                                  <br />
                                </React.Fragment>
                              ))
                            }</p>)
                        }
                      <br />
                    </div>
                    <div className="right-side">
                      <div className="design-section">
                        <h6 className="section-header">Achievements</h6>
                        <span className="finding-item">
                          {
                            typeof project.detail['5_data'] == 'string' ?
                              <p>{project.detail['5_data']}</p> :
                              Object.entries(project.detail['5_data']).map((e, index) => <p key={index}>{Object.assign([], e[1]).join('')}</p>)
                          }
                         </span>
                        <br />
                      </div>
                      <div className="data-section">
                        <h6 className="section-header">Details</h6>
                        <ul className="solutions-list">
                          {project.detail['6_design'] && project.detail['6_design'].map((item: any, index: any) => (
                            <li key={index} className="solution-item">{item}</li>
                          ))}
                        </ul>
                        <div className="recognition">
                          <span><img className="bullet-point-icon recognition-img" src={bulletPointIcons.recognition} alt="Recognition" style={ !project.detail['7_recognition'] ? { display: 'none' } : {} } /></span>
                          <ul className="recognition-list">
                            {project.detail['7_recognition'] && project.detail['7_recognition'].map((item: any, index: any) => (
                              typeof item == 'string' ?
                              <li key={index} className="recognition-item">{item}</li> :
                              <li key={index} className="recognition-item"><a href={item.url} target="blank">{item.name}</a></li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`carousel-container ${deviceType}`} style={{ width: '100%' }}>
                    <CustomCarousel deviceType={deviceType} images={carouselImages} />
                  </div>
                  {/* !isMobileDevice && <button onClick={scrollTo} className="go-to-top">GO TO TOP<span className="lines">___</span></button>*/}
                </div>
                {isMobileDevice && <button onClick={scrollTo} className="go-to-top">GO TO TOP<span className="lines">___</span></button>}
              </Fragment>
            </Accordion.Collapse>
          </Card.Body>
        </Card>
      </Accordion>
    </div >
  )
}

export default ProjectComponent;
