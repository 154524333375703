import React from 'react';

type TagsProps = {
  tags: string[]
}

const TagsSection: React.FC<TagsProps> = ({ tags }) => {
  return (
    <div className="tags-section">
      {tags.map((tag: string, index) => index === 0 ? <span key={tag}> {`${tag}`}</span> : <span key={tag}> {`· ${tag}`}</span>)}
    </div>
  )
}

export default TagsSection;
