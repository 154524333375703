import React from 'react';
import ScrollAnim from 'rc-scroll-anim';
import TweenOne from 'rc-tween-one';
import BannerInfo from '../../models/bannerInfo';

const ScrollOverPack = ScrollAnim.OverPack;

type Props = {
  bannerInfo: BannerInfo,
  deviceType: string
}

const HeroBanner: React.FC<Props> = ({ bannerInfo, deviceType }) => {
  return (
    <div id="top" className={"hero-banner-container " + deviceType}>
      <div className={"row " + deviceType} style={{ display: 'none' }}>
        <div className={"low-layer char-h " + deviceType}><span>H</span></div>
        <div className={"low-layer char-e " + deviceType}><span>E</span></div>
        <div className={"low-layer char-l " + deviceType}><span>L</span></div>
        <div className={"low-layer char-ll " + deviceType}><span>L</span></div>
        <div className={"low-layer char-o " + deviceType}><span>O</span></div>
      </div>
      <div className={"banner-locations " + deviceType} style={{ display: 'none' }}>
        <ScrollOverPack
          className="banner-location-item"
          //replay
          playScale="92vh"
        >
          <TweenOne
            className="tween-one"
            animation={{ duration: 900, x: 10, type: 'from', ease: 'easeOutQuart', opacity: 0.4 }}
            reverseDelay={0}
          >
            <span>PARIS</span>
            <i className="fa fa-long-arrow-right" />
          </TweenOne>
        </ScrollOverPack>
        <ScrollOverPack
          className="banner-location-item"
          //replay
          playScale="92vh"
        >
          <TweenOne
            className="tween-one"
            animation={{ duration: 900, x: 10, type: 'from', ease: 'easeOutQuart', opacity: 0.3 }}
            reverseDelay={0}
          >
            <span>EDINBURGH</span>
            <i className="fa fa-long-arrow-right" aria-hidden="true"/>
          </TweenOne>
        </ScrollOverPack>
        <ScrollOverPack
          className="banner-location-item"
          //replay
          playScale="92vh"
        >
          <TweenOne
            className="tween-one"
            animation={{ duration: 900, x: 10, type: 'from', ease: 'easeOutQuart', opacity: 0.2 }}
            reverseDelay={0}
          >
            <span>LOS ANGELES</span>
            <i className="fa fa-long-arrow-right" aria-hidden="true"/>
          </TweenOne>
        </ScrollOverPack>
        <ScrollOverPack
          className="banner-location-item"
          //replay
          playScale="92vh"
        >
          <TweenOne
            className="tween-one"
            animation={{ duration: 900, x: 10, type: 'from', ease: 'easeOutQuart', opacity: 0.1 }}
            reverseDelay={0}
          >
            <span>SAN FRANCISCO</span>
          </TweenOne>
        </ScrollOverPack>
      </div>
      <h3 className={"hero-banner-title " + deviceType}>{bannerInfo.title}</h3>
      <p className={"hero-banner-description " + deviceType}>{bannerInfo.description}</p>
    </div>
  );
};

export default HeroBanner;
